import Step1 from 'pages/events/steps/01';
import Step2 from 'pages/events/steps/02';
import Step3 from 'pages/events/steps/03';
import Step4 from 'pages/events/steps/04';
import Step5 from 'pages/events/steps/05';

export {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
};
