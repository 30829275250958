import EventApproveModal from 'pages/admin/dashboard-admin/event-details/modal/event-approve';
import EventCancelModal from 'pages/admin/dashboard-admin/event-details/modal/event-cancel';
import ChangePICModal from 'pages/admin/dashboard-admin/event-details/modal/change-pic';
import ChangeTypeFormIDModal from 'pages/admin/dashboard-admin/event-details/modal/change-typeform-id';

export {
  EventApproveModal,
  EventCancelModal,
  ChangePICModal,
  ChangeTypeFormIDModal,
};
