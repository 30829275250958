import Loading from "pages/loading";
import ForgotPassword from "pages/forgot-password";
import ResetPassword from "pages/reset-password";
import SignIn from "pages/sign-in";
import SignUp from "pages/sign-up";
import Welcome from "pages/welcome";
import Settings from "pages/settings";
import EventHistory from "pages/event-history";
import EventHistoryDetail from "pages/event-history/detail";
import Events from "pages/events";
import Event from "pages/event";
import Dashboard from "pages/dashboard";
import FullReport from "pages/dashboard/fullreport";
import FoodInformation from "pages/food-information";
import GuestList from "pages/guest-list";
import TableManagement from "pages/table-management";
import Invitation from "pages/invitation";
import EventDetails from "pages/event-details";
import GuestRequest from "pages/guest-request";
import CheckIn from "pages/check-in";
import VerifyEmail from "pages/verify-email";
import UserVerification from "pages/user-verification";
import Home from "pages/home";
import PrivacyPolicy from "pages/privacy-policy";
import TypeForm from "pages/form";

export {
  Loading,
  ForgotPassword,
  ResetPassword,
  SignIn,
  SignUp,
  Welcome,
  Settings,
  EventHistory,
  EventHistoryDetail,
  Events,
  Event,
  Dashboard,
  FullReport,
  FoodInformation,
  GuestList,
  TableManagement,
  Invitation,
  EventDetails,
  GuestRequest,
  CheckIn,
  VerifyEmail,
  UserVerification,
  Home,
  PrivacyPolicy,
  TypeForm,
};
