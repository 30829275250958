import Button from 'shared/button/style';
import Heading from 'shared/heading/style';
import Input from 'shared/input/style';
import TextArea from 'shared/textarea/style';
import Link from 'shared/link/style';

export {
  Button,
  Heading,
  Input,
  TextArea,
  Link,
};
