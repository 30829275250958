import ForgotPasswordAdmin from 'pages/admin/forgot-password';
import ResetPasswordAdmin from 'pages/admin/reset-password';
import SignInAdmin from 'pages/admin/sign-in';
import DashboardAdmin from 'pages/admin/dashboard-admin';

export {
  ForgotPasswordAdmin,
  ResetPasswordAdmin,
  SignInAdmin,
  DashboardAdmin,
};
